import { useEffect, useState, useContext, useRef } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { Button, Box, Stack } from '@mui/material'
import { motion } from 'framer-motion'
import { useTheme } from '@mui/material/styles'
import { scaleLinear } from 'd3-scale'

import { Foot } from './foot'
import { Logo } from './logo'
export const Head = ({ scroll }) => {
  const theme = useTheme()

  return (
    <>
      {/* <header
        style={{
          top: 0,
          right: 0,
          width: '60px',
          height: '100vh',
          position: 'fixed',
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20vh 0',
          display: 'none',
          // background: theme.palette.light[0],
        }}
      >
        {[
          { name: 'intro', path: '/' },
          { name: 'skills', path: '/' },
          { name: 'projects', path: '/' },
        ].map(() => (
          <>
            <motion.div
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '5px',
                background: theme.palette.light[2],
              }}
            ></motion.div>
          </>
        ))}
        <motion.div
          style={{
            width: '10px',
            height: '10px',
            borderRadius: '5px',
            background: 'green',
            position: 'absolute',
            y: `calc(${scaleLinear(
              [0, 0.2, 0.6, 1],
              [0, 35, 60, 80]
            )(scroll)}vh - 0px)`,
          }}
        ></motion.div>
      </header> */}

      <div
        style={{
          background: theme.palette.light[0],
        }}
      >
        <Outlet />
      </div>
    </>
  )
}
