import { useEffect, useState, useContext, useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { Stack } from '@mui/material'
import { Timeline } from '../../cv//timeline'
import { useWindowSize } from 'usehooks-ts'

export const Skills = ({ scroll }) => {
  const theme = useTheme()
  const { width, height } = useWindowSize()

  return (
    <>
      <Stack
        sx={{
          width: '100vw',
          pb: '100px',
          background: theme.palette.light[3],
        }}
        alignItems={'center'}
      >
        <motion.h2
          style={{
            display: 'flex',
            flexDirection: 'row',
            color: theme.palette.light[1],
          }}
        >
          <div className={'m tungsten'}>skills</div>
        </motion.h2>
        <motion.h2
          style={{
            display: 'flex',
            flexDirection: 'row',
            color: theme.palette.light[1],
          }}
          className={'d'}
          initial={{ x: '0vw' }}
          animate={{ x: '-2400vw' }}
          transition={{ ease: 'linear', duration: 2000 }}
        >
          {[...Array(100).keys()].map((i) => (
            <motion.div
              key={'s' + i}
              style={{
                marginRight: '4vw',
              }}
              className='tungsten d'
            >
              skills
            </motion.div>
          ))}
        </motion.h2>

        <div
          style={{
            position: 'relative',
            width: '100%',
            paddingTop: '100px',
            marginBottom: '100px',
          }}
          className={'stack'}
        >
          <motion.div
            style={{
              originX: 0.5,
              originY: 0,
            }}
          >
            <Timeline />
          </motion.div>
          <motion.div
            style={{
              position: 'absolute',
              color: theme.palette.light[0],
              lineHeight: '1.2em',
              pointerEvents: 'none',
              fontWeight: 300,
            }}
            // className={'tungsten'}
            className={'whitney skills-text'}
          >
            Here are some of tools I use.<br></br>
            <br></br>With these tools, I create full stack applications
            featuring innovative data visualizations, oauth 2.0, efficient
            backend communication and more.
            <br></br>
          </motion.div>
        </div>
        <Stack sx={{}}>
          <motion.div
            style={{
              color: theme.palette.light[1],
              fontSize: '1.2vw',
              margin: '1vw auto',
            }}
          >
            other tools I've played with
          </motion.div>
          <motion.div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              width: '50vw',
              color: 'white',
              fontSize: '1.3vw',
              justifyContent: 'center',
            }}
          >
            {[
              'Blender',
              'Premiere Pro',
              'After Effects',
              'Logic Pro',
              'Illustrator',
              'Photoshop',
              'XD',
              'visionOS',
              'SwiftUI',
            ].map((d, i) => (
              <motion.div
                style={{
                  margin: '1vw 2vw',
                  padding: '.25vw 1.3vw',
                  borderRadius: '1.5vw',
                  background: theme.palette.light[2],
                }}
              >
                {d}
              </motion.div>
            ))}
          </motion.div>
        </Stack>
      </Stack>
    </>
  )
}
