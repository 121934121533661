import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: { main: '#EEFFEF', light: '#FFF', dark: '#7EDD83' },
    purple: ['#FFF', '#1C1C20', '#26262F', '#26262F', '#222', '#49495A'],
    black: ['#000'],
    light: ['#FFF6ED', '#6C7C96', '#343E4C', '#121D2B'],
    logos: ['#007ebb'],
  },

  typography: {
    fontFamily: ['Roboto Mono'],
  },
})

export default theme
