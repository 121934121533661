import { useEffect, useState, useContext, useRef } from 'react'
import { motion, useInView } from 'framer-motion'
import { randomUniform, randomExponential, randomLogNormal } from 'd3-random'
import { scaleLinear } from 'd3-scale'
import { color } from 'd3-color'
import { useTheme } from '@mui/material/styles'
import { Stack } from '@mui/material'
import _ from 'lodash'
import { skills } from './skills'
import { timeline } from './timeline'
export const Timeline = ({}) => {
  const theme = useTheme()
  const XEND = 80
  const YEAR = 2023
  const ref = useRef(null)
  const isInView = useInView(ref)

  const X = scaleLinear([2015, YEAR], [10, XEND])

  return (
    <div className={'timeline'}>
      <div ref={ref}>
        {[...Array(YEAR - 2014).keys()].map((d, i) => (
          <motion.div
            style={{
              color: theme.palette.light[0],
              x: X(d + 2015) + '%',
              position: 'absolute',
              fontWeight: 800,
              fontSize: '13px',
              y: '-40px',
            }}
            initial={{
              opacity: 0,
              x: 0,
            }}
            animate={{
              opacity: isInView && 1,
              x: isInView && X(d + 2015) + 'vw',
            }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ duration: 0.5, delay: i * 0.05, ease: 'easeOut' }}
          >
            {d === 0 || d == 4 || d == 8 ? d + 2015 : '|'}
          </motion.div>
        ))}
      </div>
      {skills.map((d, i) => (
        <>
          <motion.div
            key={'t' + i}
            style={{
              borderRadius: 20,
              position: 'absolute',
              x: `calc(${X(d.year)}vw + 0px)`,
              y: `calc(${i * 5}vw + 50px)`,
              height: 10,
            }}
            initial={{
              width: 0,
            }}
            whileInView={{
              width: `calc(${XEND - X(d.year)}vw + 15px)`,
              background: `linear-gradient(to left, ${d.color} 0%, transparent 100%)`,
            }}
            viewport={{ once: true, amount: 0.8 }}
            // transition={{ duration: 1, delay: i * 0.2 + 0.5, ease: 'easeIn' }}
          />
          <motion.div
            key={'t2' + i}
            style={{
              y: `calc(${i * 5}vw + 25px)`,
              position: 'absolute',
              width: 60,
              height: 60,
              padding: 10,
              borderRadius: 70,
              // background: theme.palette.white[4],
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            initial={{
              opacity: 0,
              x: `calc(${X(d.year)}vw - 20px)`,
            }}
            whileInView={{
              opacity: 1,
              x: `calc(${XEND}vw + 30px)`,
            }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{
              duration: 1,
              // delay: i * 0.2 + 0.5,
              ease: 'easeOut',
            }}
          >
            {d.img('2.3vw')}
          </motion.div>
          <motion.div
            style={{
              y: `calc(${i * 5}vw + 35px)`,
              position: 'absolute',
              width: 60,
              height: 60,
              padding: 10,
              color: d.color,
            }}
            initial={{
              opacity: 0,
              x: `calc(${X(d.year)}vw - 0px)`,
            }}
            whileInView={{
              opacity: 1,
              x: `calc(${XEND}vw + 100px)`,
            }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{
              duration: 1,
              // delay: i * 0.2 + 0.5,
              ease: 'easeOut',
            }}
          >
            {d.name}
          </motion.div>
        </>
      ))}
    </div>
  )
}
