import { useEffect, useState, useContext, useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import { Link } from 'react-router-dom'
import {
  motion,
  useAnimation,
  useScroll,
  useInView,
  useTransform,
  useMotionValueEvent,
} from 'framer-motion'
import { scaleLinear, scaleQuantize } from 'd3-scale'
import { Stack } from '@mui/material'
import { Vizlogo } from './vizlogo'
import _ from 'lodash'
import { randomUniform } from 'd3'

export const Project = ({ d, i, scroll }) => {
  const [text, setText] = useState('')
  const theme = useTheme()

  // const FULL = ''.concat(`${i + 1}.`, d.name, d.desc)

  // useEffect(() => {
  //   setText(FULL.substring(0, Math.round(scroll * 2 * _.size(FULL))))
  // }, [scroll])

  return (
    <section
      key={d.name}
      style={{
        width: '100vw',
        height: '300px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2vw',
        borderBottom: '1px solid #000',
      }}
    >
      <motion.div
        style={{
          height: '100%',
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        className='project-text'
      >
        <motion.div>{i + 1 + '. ' + d.name}</motion.div>
        <motion.div>{d.desc}</motion.div>
      </motion.div>
      <motion.div
        style={{
          width: '20%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {d.svg()}
      </motion.div>
    </section>
  )
}
