import { useEffect, useState, useContext, useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import { motion, useScroll, useMotionValueEvent } from 'framer-motion'
import { Stack, Box } from '@mui/material'
import { Vizlogo } from './vizlogo'
import { Ziplogo } from './ziplogo'

import { Project } from './project2'
import { scaleLinear, scaleQuantize } from 'd3-scale'

export const Projects = ({ scroll }) => {
  const theme = useTheme()
  const [hover, setHover] = useState(0)
  const [global, setGlobal] = useState(0)

  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: [0, 1],
  })
  useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    setGlobal(latest)
  })

  const PROJECTS = [
    {
      svg: (scroll) => <Vizlogo scroll={scroll} />,
      link: 'https://viz.recipes',
      desc: 'a site that presents recipes as data visualizations. My showcase project.',
      name: 'viz.recipes',
      color: 'black',
      back: theme.palette.light[0],
    },
    {
      svg: (scroll) => <Ziplogo />,
      link: 'https://crimsonzip.org',
      desc: "I am currently helping to develop Harvard University's social fitness app.",
      name: 'crimsonzip',
      color: 'white',
      back: 'black',
    },
    // {
    //   svg: (scroll) => <Vizlogo />,
    //   link: 'https://otto.directory',
    //   desc: 'I helped otto with internal tools and backend infrastructure.',
    //   name: 'otto.directory',
    //   color: 'black',
    // },
  ]

  return (
    <>
      <Stack
        sx={{
          width: '100vw',
        }}
      >
        <motion.h2
          style={{
            display: 'flex',
            flexDirection: 'row',
            color: theme.palette.light[1],
          }}
          className={'projects'}
        >
          <div className={'m tungsten'}>projects</div>
        </motion.h2>
        <motion.h2
          style={{
            display: 'flex',
            flexDirection: 'row',
            color: theme.palette.light[1],
          }}
          className={'d'}
          initial={{ x: '0vw' }}
          animate={{ x: '-2400vw' }}
          transition={{ ease: 'linear', duration: 2000 }}
        >
          {[...Array(100).keys()].map((i) => (
            <motion.div
              key={'s' + i}
              style={{
                marginRight: '4vw',
              }}
              className='tungsten d'
            >
              projects
            </motion.div>
          ))}
        </motion.h2>

        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            borderTop: '1px solid #000',
          }}
        >
          {PROJECTS.map((d, i) => (
            <Project
              d={d}
              i={i}
              // scroll={scaleLinear([i, scroll * (i + 1)], [0, 1])(scroll * 3)}
              scroll={scaleLinear([i / 3, (i + 1) / 3], [0, 1])(global)}
              key={'pp' + i}
            />
          ))}
        </div>
      </Stack>
    </>
  )
}
