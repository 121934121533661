import { motion } from 'framer-motion'
import { randomUniform, randomExponential, randomLogNormal } from 'd3-random'
import { scaleLinear } from 'd3-scale'
import { color } from 'd3-color'
import { useTheme } from '@mui/material/styles'
import { Stack } from '@mui/material'
import _ from 'lodash'
import { skills } from './skills'
import { Timeline } from './timeline'
export const CV = ({}) => {
  const theme = useTheme()

  const X = scaleLinear().domain([2015, 2023]).range([0, 80])

  return (
    <Stack sx={{ width: '100vw', height: '100vh', bgcolor: '#000' }}>
      <Stack direction={'row'} justifyContent={'center'} sx={{ mt: 10 }}>
        <motion.div
          style={{ fontSize: '3vw', fontFamily: 'Roboto Mono' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            color: theme.palette.white[2],
          }}
        >
          elijah@munn.studio
        </motion.div>
      </Stack>
      <Timeline />
    </Stack>
  )
}
