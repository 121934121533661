import { useEffect, useState, useContext, useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { scaleLinear, scaleSqrt, scalePow } from 'd3-scale'
import { Stack } from '@mui/material'
import { useMouse } from '../mouse'
import { useWindowSize } from 'usehooks-ts'
import { useCopyToClipboard } from 'usehooks-ts'

// import portrait from './b.png'
import portrait from './a.png'

export const Intro = ({ scroll }) => {
  const theme = useTheme()
  const [count, setCount] = useState(0)
  const mouse = useMouse()
  const size = useWindowSize()
  const [value, copy] = useCopyToClipboard()
  const [hover, setHover] = useState(false)
  const [hover2, setHover2] = useState(false)

  const ref = useRef()

  return (
    <>
      <Stack
        sx={{
          width: '100vw',
          height: '100vh',
          background: theme.palette.light[0],
          position: 'relative',
        }}
        justifyContent={'flex-end'}
        alignItems={'center'}
      >
        <div
          style={{
            width: '70%',
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'center',
            position: 'absolute',
          }}
        >
          <motion.img
            src={portrait}
            style={{
              maxHeight: '80%',
              width: '60%',
              objectFit: 'contain',
              x: scalePow([0, size.width], [5, -5]).exponent(0.8)(mouse.x),
              y: scalePow([0, size.height], [0, 5]).exponent(0.8)(mouse.y),
            }}
          />
          <motion.div
            style={{
              width: '100%',
              height: `${scaleLinear([0, 0.5], [0, 50])(scroll)}vh`,
              position: 'absolute',
              bottom: -5,
              left: 0,
              borderTop: `5px solid ${theme.palette.light[2]}`,
              background: theme.palette.light[0],
            }}
          />
        </div>
        <motion.div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          initial={{ backdropFilter: 'blur(6px)' }}
          animate={{ backdropFilter: 'blur(0px)' }}
          transition={{ delay: 0.5, ease: 'easeIn', duration: 0.8 }}
        />
      </Stack>

      <motion.div
        style={{
          top: 0,
          position: 'absolute',
          y: `${scaleLinear([0, 0.4], [0, -100])(scroll)}vh`,
        }}
      >
        <Stack
          sx={{
            height: '85vh',
            width: '100vw',
            textTransform: 'uppercase',
            pointerEvents: 'none',
          }}
          justifyContent={'center'}
          alignItems={'center'}
          className={'decimal'}
        >
          {['full stack', 'developer', '& web design'].map((word, i) => (
            <>
              <div
                style={{
                  overflow: 'hidden',
                  height: '1em',
                  fontSize: '10vw',
                }}
              >
                <motion.div
                  className={i == 2 ? 'landmark' : 'decimal'}
                  style={{
                    lineHeight: '1em',
                    height: '1em',
                    color:
                      i == 2 ? theme.palette.light[1] : theme.palette.light[2],
                    fontSize: i == 2 ? '10.9vw' : '10vw',
                  }}
                  initial={{ y: '1em' }}
                  animate={{ y: 0 }}
                  transition={{
                    delay: 0.5 + i * 0.05,
                    ease: 'easeOut',
                    duration: 1,
                  }}
                >
                  {word}
                </motion.div>
              </div>
            </>
          ))}
        </Stack>
      </motion.div>
      <Stack
        sx={{
          width: '100vw',
          height: '80px',
          position: 'absolute',
          top: 'calc(100vh - 80px)',
          px: '1vw',
        }}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <motion.div
          style={{
            height: '80px',
            width: '80px',
            cursor: 'pointer',
            pointerEvents: 'all',
          }}
          whileTap={{ scale: 0.9 }}
          onClick={() =>
            window.open(
              'https://www.linkedin.com/in/elijah-munn-a24339247/',
              '_blank',
              'noreferrer'
            )
          }
        >
          <motion.svg
            viewBox='0 0 77.094 77.094'
            height={'100%'}
            style={{ padding: '14px', cursor: 'pointer' }}
            stroke={theme.palette.light[1]}
            strokeWidth={'3px'}
            fill={'none'}
          >
            <motion.path
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ delay: 0.6, duration: 2 }}
              d='m10.547,74.547h56c4.418,0,8-3.582,8-8V10.547c0-4.418-3.582-8-8-8H10.547C6.129,2.547,2.547,6.129,2.547,10.547v56c0,4.418,3.582,8,8,8Z'
            />
            <motion.path
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ delay: 0.6, duration: 2 }}
              d='m64.547,64.547h-10.684v-18.198c0-4.989-1.896-7.778-5.845-7.778-4.296,0-6.541,2.902-6.541,7.778v18.198h-10.297V29.88h10.297v4.67s3.096-5.729,10.452-5.729,12.617,4.49,12.617,13.777v21.949ZM18.896,25.341c-3.507,0-6.349-2.864-6.349-6.397s2.842-6.397,6.349-6.397,6.348,2.864,6.348,6.397-2.84,6.397-6.348,6.397Zm-5.317,39.206h10.737V29.88h-10.737v34.667Z'
            />
          </motion.svg>
        </motion.div>

        <motion.div
          style={{
            height: '80px',
            width: '80px',
            cursor: 'pointer',
            pointerEvents: 'all',
          }}
          whileTap={{ scale: 0.9 }}
          onClick={() => {
            copy('elijah@munn.studio')
            setHover(false)
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <motion.div
            style={{
              position: 'absolute',
              bottom: '1vw',
              right: '1vw',
              height: '2.3em',
              padding: '0em .7em',
              cursor: 'pointer',
              pointerEvents: 'all',
              borderRadius: '10vw',
              originX: 1,
              originY: 1,
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
            className='glass pop'
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: hover ? 1 : 0, scale: hover ? 1 : 0 }}
            transition={{ type: 'spring', stiffness: 200, damping: 16 }}
          >
            <div style={{ padding: '1em' }}>copy&nbsp;my&nbsp;email</div>
            <motion.svg height={'75%'} viewBox='0 -960 960 960'>
              <motion.path
                initial={{ pathLength: 0 }}
                whileInView={{ pathLength: 1 }}
                d='M250.769-135.615q-23.058 0-39.221-16.164-16.164-16.163-16.164-39.221v-459.923q0-6.577 4.458-10.981 4.459-4.404 11.116-4.404t10.926 4.404q4.27 4.404 4.27 10.981V-191q0 9.231 7.692 16.923 7.692 7.693 16.923 7.693h335.539q6.577 0 10.981 4.458t4.404 11.115q0 6.657-4.404 10.927-4.404 4.269-10.981 4.269H250.769Zm101.538-101.539q-23.057 0-39.221-16.163-16.163-16.164-16.163-39.221v-467.693q0-23.058 16.163-39.221 16.164-16.164 39.221-16.164H700q23.058 0 39.221 16.164 16.164 16.163 16.164 39.221v467.693q0 23.057-16.164 39.221-16.163 16.163-39.221 16.163H352.307Zm0-30.769H700q9.231 0 16.923-7.692 7.692-7.693 7.692-16.923v-467.693q0-9.231-7.692-16.923-7.692-7.692-16.923-7.692H352.307q-9.23 0-16.923 7.692-7.692 7.692-7.692 16.923v467.693q0 9.23 7.692 16.923 7.693 7.692 16.923 7.692Zm-24.615 0v-516.923 516.923Z'
              />
            </motion.svg>
          </motion.div>
          <motion.svg
            viewBox='0 -960 960 960'
            height={'100%'}
            style={{ padding: '9px', cursor: 'pointer' }}
            strokeWidth={'5px'}
          >
            <motion.path
              initial={{
                fill: theme.palette.light[1],
                stroke: theme.palette.light[1],
                strokeWidth: 5,
              }}
              animate={{
                fill: value ? 'green' : theme.palette.light[1],
                stroke: value ? 'green' : theme.palette.light[1],
                strokeWidth: value ? 28 : 5,
              }}
              transition={{ type: 'spring', stiffness: 200, damping: 16 }}
              d='M175.384-200q-23.057 0-39.221-16.163Q120-232.327 120-255.384v-449.232q0-23.057 16.163-39.221Q152.327-760 175.384-760h609.232q23.057 0 39.221 16.163Q840-727.673 840-704.616v449.232q0 23.057-16.163 39.221Q807.673-200 784.616-200H175.384Zm633.847-505.769L496-496.077q-4 2-7.885 3.5-3.884 1.5-8.115 1.5t-8.115-1.5q-3.885-1.5-7.116-3.5l-314-209.692v450.385q0 10.769 6.923 17.692t17.692 6.923h609.232q10.769 0 17.692-6.923t6.923-17.692v-450.385ZM480-521.231l315.231-208H165.769l314.231 208ZM150.769-705.769V-698v-23.51 1.266-8.987 8.385-1.682V-698v-7.769 475-475Z'
            />
          </motion.svg>
        </motion.div>
      </Stack>
      <Stack
        sx={{
          width: '100vw',
          height: '20vw',
          background: theme.palette.light[0],
        }}
        justifyContent={'flex-end'}
        alignItems={'center'}
      >
        <motion.div
          className={'archer subheader'}
          style={{
            height: '100%',
            textAlign: 'center',
            color: 'black',
          }}
        >
          My focus is on elegant and intuitive user interfaces with efficient
          back ends.
        </motion.div>
      </Stack>
    </>
  )
}
