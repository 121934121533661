import { useEffect, useState, useContext, useRef } from 'react'
import { Routes, Route, Outlet, Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'
import { randomUniform } from 'd3-random'
import { scaleLinear, scaleSqrt, scalePow } from 'd3-scale'
import { Stack } from '@mui/material'
import { useMouse } from './mouse'
import { Intro } from './sections/intro'
import { Skills } from './sections/skills'
import { Projects } from './sections/projects2'

export const Home = ({ scroll }) => {
  const theme = useTheme()
  const [count, setCount] = useState(0)
  const mouse = useMouse()
  const ref = useRef()

  return (
    <>
      <motion.div
        style={{
          width: '100vw',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2, ease: 'easeIn' }}
      >
        <Intro scroll={scroll} />
        <Skills scroll={scroll} />
        <Projects scroll={scroll} />
      </motion.div>
    </>
  )
}
