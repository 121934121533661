import { useEffect, useState, useContext, useRef } from 'react'
import { Outlet, Link } from 'react-router-dom'
import { Stack } from '@mui/material'
import { motion } from 'framer-motion'
import { useTheme } from '@mui/material/styles'
export const Ziplogo = ({}) => {
  const theme = useTheme()
  const [hover, setHover] = useState(0)

  return (
    <motion.svg
      viewBox='0 0 128 128'
      style={{ fill: '#A51C30', cursor: 'pointer' }}
      width={'60%'}
      animate={{ scale: hover ? 0.9 : 1 }}
      onMouseEnter={() => setHover(1)}
      onMouseLeave={() => setHover(0)}
      onClick={() =>
        window.open('https://crimsonzip.org', '_blank', 'noreferrer')
      }
    >
      <path d='m108.03,13.342c3.021,0,5.47,2.449,5.47,5.47v33.336c0,35.037-37.214,57.387-47.432,62.863-1.612.864-3.53.862-5.142-.002-2.964-1.588-8.195-4.592-14.129-8.91-2.121-1.544-2.866-4.373-1.757-6.751L83.669,16.5c.898-1.927,2.832-3.158,4.957-3.158h19.404Z' />
      <path d='m81.109,16.39l-38.27,82.078c-.796,1.707-3.005,2.171-4.42.929-2.16-1.896-4.317-3.943-6.408-6.142-.83-.873-1.028-2.181-.518-3.272L66.439,15.042c.484-1.037,1.524-1.7,2.668-1.7h10.06c1.567,0,2.604,1.627,1.942,3.048Z' />
      <path d='m59.221,16.5l-31.285,67.081c-.686,1.47-2.71,1.649-3.644.323-1.027-1.459-2.003-2.965-2.923-4.516-.478-.807-.514-1.808-.117-2.658L50.046,14.987c.468-1.004,1.475-1.645,2.582-1.645h4.581c1.624,0,2.698,1.687,2.012,3.158Z' />
      <path d='m41.344,15.759l-22.795,48.903c-.741,1.59-3.084,1.383-3.514-.318-.303-1.197-.562-2.412-.775-3.644-.08-.465-.011-.945.188-1.373L35.27,14.667c.377-.808,1.188-1.325,2.08-1.325h2.454c1.243,0,2.065,1.29,1.54,2.417Z' />
      <path d='m26.446,15.028l-10.686,22.929c-.536,1.15-2.259.768-2.259-.501v-1.434c0-.173.038-.344.111-.501l10.018-21.495c.195-.418.614-.685,1.074-.685h.669c.867,0,1.44.9,1.074,1.686Z' />
      <path d='m15.716,13.342c.428,0,.711.444.53.832l-2.746,5.884v-6.132c0-.323.262-.585.585-.585h1.631Z' />
    </motion.svg>
  )
}
