import { useEffect, useState, useContext, useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import { motion } from 'framer-motion'

export const Vizlogo = ({ scroll }) => {
  const theme = useTheme()
  const [hover, setHover] = useState(0)
  console.log(hover)
  return (
    <>
      <motion.svg
        viewBox='0 0 64 64'
        width={'60%'}
        onMouseEnter={() => setHover(1)}
        onMouseLeave={() => setHover(0)}
        style={{ cursor: 'pointer' }}
        onClick={() =>
          window.open('https://viz.recipes', '_blank', 'noreferrer')
        }
      >
        <motion.path
          animate={{ x: hover ? 2 : 0, y: hover ? 3 : 0 }}
          transition={{
            type: 'spring',
            stiffness: 200,
          }}
          style={{ fill: '#146D08' }}
          d='M21.73,52h-.52A20.19,20.19,0,0,1,6.86,45.75,18.67,18.67,0,0,1,1.92,32.17c.43-10.88,8.74-19.27,19.32-19.49a19,19,0,0,1,13.84,5.49A19.8,19.8,0,0,1,41,32.36h0a20.18,20.18,0,0,1-6.21,14.21A18.77,18.77,0,0,1,21.73,52Zm-.05-32h-.29c-6.68.14-11.94,5.53-12.21,12.52a11.38,11.38,0,0,0,3,8.33,13,13,0,0,0,9.25,3.91,11.43,11.43,0,0,0,8.29-3.37,12.87,12.87,0,0,0,4-9,12.54,12.54,0,0,0-3.73-9A11.58,11.58,0,0,0,21.68,19.93Z'
        />
        <motion.path
          style={{
            originX: 0.5,
            originY: 1,
            fill: '#8798D8',
            pathLength: scroll,
          }}
          animate={{ rotate: hover ? -8.7 : 0 }}
          transition={{
            type: 'spring',
            stiffness: 200,
          }}
          d='M62,15.32a9.6,9.6,0,0,0-2.73-6.9,9.76,9.76,0,0,0-7-2.92,10,10,0,0,0-9.77,9.68,9.41,9.41,0,0,0,2.67,7A9.93,9.93,0,0,0,50,25c.16,5.61.07,9.38,0,13-.09,3.79-.17,7.36,0,12.74a2.27,2.27,0,0,0,2.27,2.19h.08a2.27,2.27,0,0,0,2.19-2.34c-.17-5.25-.09-8.76,0-12.48s.18-7.47,0-13.13A10.2,10.2,0,0,0,62,15.32Zm-3.63-.09a6.41,6.41,0,0,1-6.14,6.41,6.23,6.23,0,0,1-4.47-2,5.81,5.81,0,0,1-1.67-4.31,6.43,6.43,0,0,1,6.14-6.23A6.14,6.14,0,0,1,56.65,11a6,6,0,0,1,1.7,4.27Z'
        />
      </motion.svg>
    </>
  )
}
