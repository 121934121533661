import { useState, useEffect } from 'react'
import './App.css'
import { Routes, Route, useLocation } from 'react-router-dom'
import { Home } from './main/home/home'
import { Head } from './main/head/head'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import {
  motion,
  useScroll,
  useTransform,
  useMotionValueEvent,
} from 'framer-motion'
import { AnimatePresence } from 'framer-motion'
import theme from './theme'
import { CV } from './main/cv/cv'
import './small.css'
export const App = () => {
  const location = useLocation()
  const { scrollY, scrollYProgress } = useScroll()
  const [scroll, setScroll] = useState(0)

  useMotionValueEvent(scrollYProgress, 'change', (latest) => {
    setScroll(latest)
  })

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes location={location} key={location.pathname}>
          <Route path={'/'} element={<Head scroll={scroll} />}>
            <Route index element={<Home scroll={scroll} />} />
            <Route path={'/cv'} element={<CV />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </>
  )
}
